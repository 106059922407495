/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* VARIABLES CSS */
:root{

  --header-height: 3rem;

  /* COLORS */
  --hue:0;
  --sat:0%;
  --title-color:hsl(var(--hue),var(--sat),20%);
  --title-color-dark:hsl(var(--hue),var(--sat),0%);
  --text-color:hsl(var(--hue),var(--sat),46%);
  --body-color:hsl(var(--hue),var(--sat),98%);
  --container-color: #fff;
  /* Font and Typography */
  --body-font:'Poppins', sans-serif;
 
  /*Font boyutları*/
  --big-font-size:3.5rem;
  --big1-font-size:3.2rem;

  --h1-fontsize:2.25rem;
  --h2-fontsize:1.5rem;
  --h3-fontsize:1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;
  /*Font genişliği*/
  --font-normal:400;
  --font-medium:500;
  --font-semi-bold:600;
  /*Margin ayarları*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* Derinlik ayarı */
  --z-tooltip: 10;
  --z-fixed:100;
  --z-modal:1000;
}

/* Ekra genişlik ayarları  */
@media screen and (max-width: 992px) {
  :root{
    --big-font-size:2.75rem;
    --h1-fontsize:1.5rem;
    --h2-fontsize:1.25rem;
    --h3-fontsize:1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* BASE */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body,
button,
input,
textarea{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}
body{
  background-color: var(--body-color);
  color: var(--text-color);
}
h1,h2,h3{
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}
ul{
  list-style: none;

}
a{
  text-decoration: none;
}
button{
  cursor: pointer;
  border: none;
  outline: none;
}
img{
  max-width: 100%;
  height: auto;
}

 /* Tekrarlayan cssler */

.section{
  padding: 6rem 0 2rem;
}
.section__title{
  font-size: var(--h1-fontsize);
  color: var(--title-color);
}

.section__subtitle{
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}
.section__title,
.section__subtitle{
  text-align: center;
}
/* Genel Düzen */
.container{
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  gap: 1.5rem;
}

/* Button özellikleri */
.button{
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}
.button:hover{
  background-color: var(--title-color-dark);
}
.button__icon{
  margin-left: var(--mb-0-5);
}
.button--flex{
  display: inline-flex;
  align-items: center;
}


/* XL Ekran */
@media screen and (max-width: 992px) {
  .container{
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);

  }
  .button{
    padding: 1rem 1.75rem;
  }

  .button__icon{
    width: 22px;
    height: 22px;
  }

}

/* L Ekran */
@media screen and (max-width: 768px) {
  body{
    margin: 0 0 var(--header-height) 0;
  }
  .section{
    padding: 2rem 0 4rem ;
  }
  .section__subtitle{
    margin-bottom: var(--mb-3);
  }
}


/* M Ekran */
@media screen and (max-width: 576px) {}

/* s Ekran */
@media screen and (max-width: 350px) {
  :root{
    --big-font-size:2.25rem;
  }


  .container{
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);

  }
  
}

